import { ProductLine, Invoice } from './types'

export const initialProductLine: ProductLine = {
  description: '',
  quantity: '1',
  rate: '0.00',
}

export const initialInvoice: Invoice = {
  logo: '',
  logoWidth: 100,
  title: 'FACTUUR',
  companyName: '',
  name: '',
  companyAddress: '',
  companyAddress2: '',
  companyCountry: 'Nederland',
  billTo: 'Aan:',
  clientName: '',
  clientAddress: '',
  clientAddress2: '',
  clientCountry: 'Nederland',
  invoiceTitleLabel: 'Factuur#',
  invoiceTitle: '',
  invoiceDateLabel: 'Factuurdatum',
  invoiceDate: '',
  invoiceDueDateLabel: 'Betaaldatum',
  invoiceDueDate: '',
  productLineDescription: 'Regel omschrijving',
  productLineQuantity: 'Aantal',
  productLineQuantityRate: 'Prijs',
  productLineQuantityAmount: 'Bedrag',
  productLines: [
    {
      description: 'Yoga les',
      quantity: '2',
      rate: '27.50',
    },
    { ...initialProductLine },
    { ...initialProductLine },
  ],
  subTotalLabel: 'Sub Totaal',
  taxLabel: 'BTW (21%)',
  totalLabel: 'TOTAAL',
  currency: '€',
  notesLabel: 'Notities',
  notes: 'Bedankt voor jullie vertrouwen',
  termLabel: 'Voorwaarden',
  term: 'Wil je de factuur zo snel mogelijk, maar niet later dan de uiterste betaaldatum voldoen?',
}
